









































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import logo from '@/components/base/mobile/InsLogo.vue';
// import DrawerUI from './InsDrawerUI.vue';
import InsButton from '@/components/base/mobile/InsButton.vue';
import shopcart from '@/components/business/mobile/header/InsShoppingCart.vue';
@Component({
  components: {
    // logo,
    shopcart,
    InsButton,
    InsDrawer: () => import('@/components/base/mobile/InsDrawer.vue')
  }
})
export default class InsHeader extends Vue {
  direction: string = 'ttb';
  drawer: boolean = false;
  showClose: boolean = true;
  show: boolean = false;
  private bigLogo:boolean = true;
  private key: string = '';
  private showMenberCentral:boolean = false;
  private showChilds:boolean[] = [];
  private drawerChange:boolean = false;
  private count:number = 0;
  private search: boolean = false;
  private showGrandChilds = {};
  menberCentral () {
    if (!this.$Storage.get('isLogin')) this.$router.push('/account/login');
    else this.$router.push('/account/memberCentral');
  }
  get ShopCart () {
    return this.$store.state.shopCart;
  }
  logout () {
    this.$Api.member.logout().then((result) => {
      if (result) this.$message('Message.SucceedInOperating');
      this.$router.push('/');
    });
  }
  getMenu () {
    this.$Api.promotion.getMenu().then((result) => {
      this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
      this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
      result.ReturnValue.HeaderMenus.forEach((element, index) => {
        if (element.Childs) this.showChilds[index] = true;
        else this.showChilds[index] = false;
        if (element.Childs) {
          element.Childs.forEach(e => {
            if (e.Childs) this.showGrandChilds[e.Value.Id] = true;
            else this.showGrandChilds[e.Value.Id] = false;
          });
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  private changeLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);
      this.Reload();
    }).catch((error) => {
      console.log(error);
    });
  }
  private To (item) {
    console.log(item);
    if (item.Type === 0) this.$router.push(item.Url);
    else if (item.Type === 2) this.$router.push('/cms/content/' + item.Value.Id);
    else if (item.Type === 4 && item.Value.Id) this.$router.push('/product/cat/' + item.Value.Id);
    this.show = false;
  }
  @Watch('language')
  onLanguageChange () {
    this.$Api.member.setUILanguage(this.language).then((result) => {
    }).catch((error) => {
      console.log(error);
    }).then(() => {
      this.getMenu();
    });
  }
  get language () {
    return this.$store.state.lang;
  }
  // changeDrawer (flag) {
  //   this.drawer = flag;
  // }
  memberCentral (e) {
    let target = e.target as HTMLElement;
    if (target.className === 'ii' && target.dataset.to) {
      this.$router.push({
        path: target.dataset.to
      });
    }
  }
  showSlideMenu () {
    this.show = !this.show;
    // this.$store.dispatch('isShowMenu', !this.$store.state.isShowMenu);
  }
  closeMenuChids (index) {
    this.showChilds[index] = !this.showChilds[index];
    this.drawerChange = !this.drawerChange;
  }
  closeMenuGrandChids (item) {
    this.showGrandChilds[item.Value.Id] = !this.showGrandChilds[item.Value.Id];
    this.drawerChange = !this.drawerChange;
  }
  created () {
    if (this.$route.name !== 'home') { this.bigLogo = false; }
    window.onscroll = () => {
      if (this.$route.name !== 'home') return;
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (scrollTop >= window.innerHeight) this.bigLogo = false;
      else this.bigLogo = true;
    };
    window.addEventListener('click', (e) => {
      let target = e.target as HTMLElement;
      if (target.className !== 'showMenberCentral') this.showMenberCentral = false;
    });
  }
  mounted () {
    this.getMenu();
  }
  @Watch('$route', { deep: true })
  onRouteChange () {
    this.showMenberCentral = false;
    this.search = false;
    if (this.$route.name !== 'home') { this.bigLogo = false; } else { this.bigLogo = true; }
  }
  @Watch('key')
  searchKeyChange () {
    this.Shake(() => {
      this.$store.dispatch('setSearchKey', this.key);
      if (this.$route.name !== 'productSearch') {
        this.$router.push({
          path: '/product/search',
          name: 'productSearch',
          params: {
            key: this.key
          }
        });
      }
    }, 1000);
  }
  @Watch('ShopCart')
  onShopCartChange () {
    this.ShopCart.then((result) => {
      this.count = result.ShopCart.Qty;
    });
  }
}
